<template>
  <footer class="relative bg-blueGray-200 pt-8 pb-6">
    <div class="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20 h-20"
      style="transform: translateZ(0);">
      <svg class="absolute bottom-0 overflow-hidden" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none"
        version="1.1" viewBox="0 0 2560 100" x="0" y="0">
        <polygon class="text-blueGray-200 fill-current" points="2560 0 2560 100 0 100"></polygon>
      </svg>
    </div>
    <div class="container mx-auto px-4">
      <div class="flex flex-wrap text-center lg:text-left">
        <div class="w-full lg:w-6/12 px-4">
          <h4 class="text-3xl font-semibold">PublicMirrorsNetwork</h4>
          <h5 class="text-lg mt-0 mb-2 text-blueGray-600">
            我们为一些重要的、专业的、非盈利性的公共网站提供公共镜像
            <br />
            让互联网成为更加开放的整体
          </h5>
          <div class="mt-6 lg:mb-0 mb-6">
            <a href="https://t.me/+kYq-LuJmFE9kNzQ1" target="_blank">
              <button
                class="bg-white text-lightBlue-400 shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2"
                type="button">
                <i class="fab fa-telegram"></i>
              </button>
            </a>
            <a href="mailto:admin@pmnet.gq" target="_blank">
              <button
                class="bg-white text-lightBlue-600 shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2"
                type="button">
                <i class="fas fa-envelope"></i>
              </button>
            </a>
          </div>
        </div>
        <div class="w-full lg:w-6/12 px-4">
          <div class="flex flex-wrap items-top mb-6">
            <div class="w-full lg:w-4/12 px-4 ml-auto">
              <span class="block uppercase text-blueGray-500 text-sm font-semibold mb-2">
                其他资源
              </span>
              <ul class="list-unstyled">
                <li>
                  <a class="text-blueGray-600 hover:text-blueGray-800 font-semibold block pb-2 text-sm"
                    href="https://mirrors.pw/">
                    Mirrors.pw
                  </a>
                </li>
                <li>
                  <a class="text-blueGray-600 hover:text-blueGray-800 font-semibold block pb-2 text-sm"
                    href="https://5050net.cn/">
                    5050net
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <hr class="my-6 border-blueGray-300" />
      <div class="flex flex-wrap items-center md:justify-between justify-center">
        <div class="w-full md:w-10/12 px-4 mx-auto text-center">
          <div class="text-sm text-blueGray-500 font-semibold py-1">
            Copyright © {{ date }} <a href="https://pmnet.gq" class="text-blueGray-500 hover:text-blueGray-800">
              PMNET
            </a>&nbsp;All rights reserved.
            <br />
            Theme by
            <a href="https://github.com/creativetimofficial/vue-notus"
              class="text-blueGray-500 hover:text-blueGray-800">
              Vue Notus
            </a> |
            Backend by <a href="https://github.com/hunshcn/gh-proxy" class="text-blueGray-500 hover:text-blueGray-800">
              hunshcn/gh-proxy
            </a> |
            Maintained by <a href="https://5050net.cn" class="text-blueGray-500 hover:text-blueGray-800">
              5050net
            </a>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  data() {
    return {
      date: new Date().getFullYear(),
    };
  },
};
</script>
