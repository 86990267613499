<template>
  <div>
    <navbar />
    <main class="profile-page">
      <section class="relative block h-350-px">
        <div class="absolute top-0 w-full h-full bg-center bg-cover" style="
            background-image: url('https://www.asset.pmnet.gq/data/file/richy-great-MAYEkmn7G6E-unsplash-zip.webp');">
          <span id="blackOverlay" class="w-full h-full absolute opacity-50 bg-black"></span>
        </div>
        <div class="top-auto bottom-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden h-70-px"
          style="transform: translateZ(0);">
          <svg class="absolute bottom-0 overflow-hidden" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none"
            version="1.1" viewBox="0 0 2560 100" x="0" y="0">
            <polygon class="text-blueGray-200 fill-current" points="2560 0 2560 100 0 100"></polygon>
          </svg>
        </div>
      </section>
      <section class="relative py-16 bg-blueGray-200">
        <div class="container mx-auto px-4">
          <div class="relative flex flex-col min-w-0 break-words bg-white w-full mb-0 shadow-xl rounded-lg -mt-64">
            <div class="px-6">
              <div class="text-center mt-12">
                <h3 class="text-4xl font-semibold leading-normal mb-2 text-blueGray-700 mb-2">
                  GitHub Proxy
                </h3>
                <div class="text-sm leading-normal mt-0 mb-12 text-blueGray-400 font-bold uppercase">
                  <i class="fas fa-bolt mr-2 text-lg text-blueGray-400"></i>
                  让您的开发和部署如虎添翼
                </div>
                <div class="flex flex-wrap justify-center">
                  <div class="relative w-full items-stretch mr-2 lg:w-6/12">
                    <span
                      class="z-10 h-full leading-snug font-normal absolute text-center text-blueGray-300 absolute bg-transparent rounded text-base items-center justify-center w-8 pl-3 py-3">
                      <i class="fab fa-github"></i>
                    </span>
                    <input type="text" v-model="originUrl" placeholder="https://github.com/..."
                      class="px-3 py-3 placeholder-blueGray-300 text-blueGray-600 relative bg-white bg-white rounded text-sm shadow outline-none focus:outline-none focus:shadow-outline w-full pl-10" />
                  </div>
                </div>
              </div>
              <div class="mt-10 py-10 border-t border-blueGray-200 text-center">
                <div class="flex flex-wrap ">
                  <div class="w-full px-4 justify-center">
                    <div v-if="!showReadme" class="justify-center mx-1 px=1">
                      <div class="relative container mx-auto w-full">
                        <span
                          class="z-10 h-full leading-snug font-normal absolute text-center text-blueGray-300 absolute bg-transparent rounded text-base items-center justify-center pl-3 py-3">
                          <i class="fab fa-github"></i>
                        </span>
                        <input type="text" id="proxiedUrl" placeholder="代理后的 URL" :value="proxiedUrl" disabled
                          class="px-3 py-3 placeholder-blueGray-300 text-blueGray-600 relative bg-white bg-white rounded text-sm shadow outline-none focus:outline-none focus:shadow-outline lg:w-8/12 pl-10" />
                        <button data-clipboard-target="#proxiedUrl" id="copyProxied"
                          class="ml-1 bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 w-1/12"
                          type="button">
                          <i class="fas fa-copy"></i>
                        </button>
                      </div>
                    </div>
                    <div v-else class="text-left justify-center flex flex-wrap container  mx-auto px-4 lg:w-9/12">
                      <div v-if="!isUrlValid" class="text-white px-6 py-4 border-0 rounded relative mt-4 bg-red-500">
                        <span class="text-base inline-block mr-5 align-middle">
                          <i class="fas fa-bell"></i>&nbsp;
                          <span class="inline-block align-middle mr-8">
                            输入的 URL 格式有误或暂时不被支持
                          </span>
                        </span>
                      </div>

                      <h4 class="w-full text-3xl font-normal leading-normal mt-0 mb-0 text-blueGray-800">
                        如何使用？
                      </h4>
                      <p class="w-full text-base font-light leading-relaxed mt-0 mb-4 text-blueGray-800">
                        将 GitHub 的链接粘贴到上方输入框内。生成代理后的链接可用于 直接下载 和 Git Clone。
                        <br />
                        此外，如果你要代理的内容受支持，你可以直接在 URL 的前面加上 <code>{{ proxyPrefix }}</code> 以使用。
                      </p>
                      <h4 class="w-full text-3xl font-normal leading-normal mt-0 mb-0 text-blueGray-800">
                        支持代理哪些内容？
                      </h4>
                      <p class="w-full text-base font-light leading-relaxed mt-0 mb-4 text-blueGray-800">
                        基于 HTTPS 协议的 Git Clone、Repository 中的文件（也就是 RAW）、Archive & Releases & Gist 的文件、Blob &
                        Suites。
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
    <footer-component />
  </div>
</template>
<script>
import Navbar from "@/components/Navbars/AuthNavbar.vue";
import FooterComponent from "@/components/Footers/Footer.vue";
import clipboardJS from 'clipboard';
import GithubIcon from "@/assets/img/github-mark.svg";

var checkWhetherUrlValid = /^((https|http):\/\/)?(github\.com\/.+?\/.+?\/(?:releases|archive|blob|raw|suites)|((?:raw|gist)\.(?:githubusercontent|github)\.com))\/.+$/

export default {
  data() {
    return {
      originUrl: "",
      GitHubIcon: GithubIcon,
    };
  },
  computed: {
    showReadme() {
      return !checkWhetherUrlValid.test(this.originUrl);
    },
    isUrlValid() {
      return this.originUrl == "" || checkWhetherUrlValid.test(this.originUrl);
    },
    proxiedUrl() {
      return `${this.proxyPrefix}/${this.originUrl}`
    },
    proxyPrefix() {
      const u = new URL(window.location);
      return u.origin;
    }
  },
  components: {
    Navbar,
    FooterComponent,
  },
  mounted() {
    let clipboard = new clipboardJS('#copyProxied')
    clipboard.on('success', () => {
      this.$noty.success('复制成功'
      )
    })
    clipboard.on('error', () => {
      this.$noty.error('复制失败，请手动复制')
    })
  }
};
</script>
