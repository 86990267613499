import { createApp } from "vue";
import { createWebHistory, createRouter } from "vue-router";
import VueNoty from "@rodrigogs/vuejs-noty"
// styles

import "@fortawesome/fontawesome-free/css/all.min.css";
import "@/assets/styles/tailwind.css";
import '@rodrigogs/vuejs-noty/dist/vuejs-noty.css'

// mouting point for the whole app
import App from "@/App.vue";

// // layouts

// import Admin from "@/layouts/Admin.vue";
// import Auth from "@/layouts/Auth.vue";

// // views for Admin layout

// import Dashboard from "@/views/admin/Dashboard.vue";
// import Settings from "@/views/admin/Settings.vue";
// import Tables from "@/views/admin/Tables.vue";
// import Maps from "@/views/admin/Maps.vue";

// // views for Auth layout

// import Login from "@/views/auth/Login.vue";
// import Register from "@/views/auth/Register.vue";

// // views without layouts

// import Landing from "@/views/Landing.vue";
import Main from "@/views/Main.vue";
// import Index from "@/views/Index.vue";

// routes

const routes = [
  {
    path: "/",
    component: Main,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

createApp(App).use(router).use(VueNoty).mount("#app");
